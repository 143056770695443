<template>
    <div>
        <component :is="containerSizeValue">
            <text-el fs="4" mb="4" :additional-classes="[headingClasses]">{{heading}}</text-el>
            <div>
                <slot></slot>
            </div>
        </component>
    </div>
</template>

<script>
export default {
    name: 'TablePage',
    props: {
        heading: { type: String, default: 'Page Heading' },
        headingClasses: { type: [String, Array], default: 'Page Heading' },
        containerSize: { type: String, default: 'sm' }
    },
    computed: {
        containerSizeValue () {
            if (this.containerSize === 'sm') {
                return 'container';
            } else {
                return 'container-fluid';
            }
        }
    }
};
</script>

<style scoped>

</style>
