import guards from './guards';
import store from '@/store';
const Layout = () => import(/* webpackChunkName: "layout" */'../layouts/MainLayout');
const Home = () => import(/* webpackChunkName: "home" */'../views/home/Home');
const About = () => import(/* webpackChunkName: "home" */'../views/About');
const Test = () => import(/* webpackChunkName: "home" */'../views/CommonComponents');

const currentUser = store.getters.currentUser;
console.log('current user - layout', currentUser);
// const setRedirect = async function () {
//     console.log(store.getters.currentUser);
//     return store.getters.currentUser.admin === true ? '/dashboard/' : '/animal-page/';
// };
export default {
    path: '/',
    name: 'DashboardLayout',
    // redirect: store.getters.currentUser.admin === true ? '/dashboard/' : '/animal-page/',
    redirect: '/dashboard/',
    // redirect: setRedirect(),
    component: Layout,
    beforeEnter: guards.loggedInGuard,
    children: [
        {
            path: '/dashboard/',
            name: 'Home',
            component: Home
        },
        {
            path: '/about/',
            name: 'About',
            component: About
        },
        {
            path: '/test/',
            name: 'test',
            component: Test
        },
        {
            path: '/components/',
            name: 'About',
            component: () => import(/* webpackChunkName: "home" */'../views/dev/ui/UiComponentsPage')
        },
        {
            path: '/change-password/',
            name: 'ChangePassword',
            component: () => import('../views/auth/ChangePassword')
        },
        {
            path: '/exp/',
            name: 'ExperimentPage',
            component: () => import('../views/exp/ExperimentPage')
        }
    ]
};
