<template>
    <div class="bs-5">
        <div class="py-2 px-3 fl-te-c c-border text-white bg-primary w-100">
            <div class="">
                <h5 v-if="heading !==''">{{ heading }}</h5>
                <div class="fl-x" v-else>
                    <slot name="header"></slot>
                </div>
            </div>
            <div class="btn-group btn-block">
                <slot name="buttons"></slot>
            </div>
        </div>
        <custom-size-el :min-height="minHeight" :max-height="maxHeight" class="font-proxima-regular bg-2 of-a thin-scrollbar sb-color-fourth"
                 :class="{'px-lg-4 px-3 pt-3 pb-5': padding, '':minHeight }">
            <slot></slot>
        </custom-size-el>
    </div>
</template>

<script>
export default {
    name: 'DetailsCard',
    props: {
        heading: {
            type: String,
            default: ''
        },
        headerColored: {
            type: Boolean,
            default: true
        },
        minHeight: {
            type: String,
            default: 'true'
        },
        maxHeight: {
            type: String,
            default: '70vh'
        },
        padding: {
            type: Boolean,
            default: true
        }
    }
};
</script>

<style scoped>
.c-border {
    border-radius: 6px 6px 0px 0px !important;
}
</style>
