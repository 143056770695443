import VueTableCard from 'lego-framework/src/tables/VueTableCard';

export default {
    name: 'vue-table-card-customized',
    extends: VueTableCard,
    props: {
        containerClasses: { type: String, default: 'round-1' },
        tableClass: { type: String, default: 'table table-bordered' }
    }
};
