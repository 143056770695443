import guards from './guards';

const Layout = () => import(/* webpackChunkName: "layout" */'../layouts/MainLayout');

export default {
    path: '/',
    name: 'DashboardLayout',
    redirect: '/app/',
    component: Layout,
    beforeEnter: guards.loggedInGuard,
    children: [
        {
            path: '/transactions/',
            name: 'TransactionsPage',
            component: () => import(/* webpackChunkName: "home" */'../views/transactions/transaction/TransactionsPage')
        },
        {
            path: '/refund/',
            name: 'RefundPage',
            component: () => import(/* webpackChunkName: "home" */'../views/transactions/refund/RefundPage')
        },
        {
            path: '/transaction-details/:id',
            name: 'TransactionDetails',
            component: () => import(/* webpackChunkName: "home" */'../views/transactions/transaction/TransactionDetailsPage')
        }
    ]
};
