import guards from './guards';

const Layout = () => import(/* webpackChunkName: "layout" */'../layouts/MainLayout');

export default {
    path: '/',
    name: 'DashboardLayout',
    redirect: '/app/',
    component: Layout,
    beforeEnter: guards.loggedInGuard,
    children: [
        {
            path: '/indications/',
            name: 'IndicationsPage',
            component: () => import(/* webpackChunkName: "home" */'../views/pharmacy/indications/IndicationsPage')
        },
        {
            path: '/indications-details/:id',
            name: 'IndicationDetailsPage',
            component: () => import(/* webpackChunkName: "home" */'../views/pharmacy/indications/indication-details-page/IndicationDetailsPage')
        },

        {
            path: '/preperations/',
            name: 'PreperationsPage',
            component: () => import(/* webpackChunkName: "home" */'../views/pharmacy/preparations/PreperationsPage')
        },
        {
            path: '/preperations-details/:id',
            name: 'PreparationDetailsPage',
            component: () => import(/* webpackChunkName: "home" */'../views/pharmacy/preparations/preparation-detailspage/Preparation-detailspage')
        },
        {
            path: '/indications-issue-reported/',
            name: 'IndicationsIssueReported',
            component: () => import(/* webpackChunkName: "home" */'../views/pharmacy/indications-issue-reported/IndicationsIssuesReportedPage')
        },
        {
            path: '/indications-suggestions-page/',
            name: 'IndicationsSuggestionsPage',
            component: () => import(/* webpackChunkName: "home" */'../views/pharmacy/indications-suggestions-page/IndicationsSuggestionsPage')
        },
        {
            path: '/preparations-issues-reported/',
            name: 'PreparationsIssueReported',
            component: () => import(/* webpackChunkName: "home" */'../views/pharmacy/preparations-issues-reported/PreparationsIssuesReportedPage')
        },
        {
            path: '/preparations-suggestions/',
            name: 'PreparationsSuggestions',
            component: () => import(/* webpackChunkName: "home" */'../views/pharmacy/preparations-suggestions/PreparationsSuggestionsPage')
        },
        {
            path: '/preparations-add/',
            name: 'PreparationsAdd',
            component: () => import(/* webpackChunkName: "home" */'../views/pharmacy/preparations/preparation-detailspage/add-preparation')
        },
        {
            path: '/preparations-edit/:id/',
            name: 'PreparationsEdit',
            component: () => import(/* webpackChunkName: "home" */'../views/pharmacy/preparations/preparation-detailspage/edit-preparation')
        },
        {
            path: '/preparations-suggestions-approved/',
            name: 'PreparationSuggestionsApproved',
            component: () => import(/* webpackChunkName: "home" */'../views/pharmacy/preparation-suggetion-approved/preparationSuggestionApproved')
        },
        {
            path: '/indication-suggestions-approved/',
            name: 'IndicationSuggestionsApproved',
            component: () => import(/* webpackChunkName: "home" */'../views/pharmacy/Indiacation-suggetion-approved/indicationSuggestionsApproved')
        }
    ]
};
