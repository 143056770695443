<template>
    <div class="text-muted font-weight-700" :class="[marginClasses, paddingClasses]">
        <slot></slot>
    </div>
</template>

<script>
import marginsAndPaddingsMixin from 'lego-framework/src/mixins/margin-and-padding/marginsAndPaddingsMixin';
export default {
    name: 'FormInfo',
    mixins: [marginsAndPaddingsMixin]
};
</script>

<style scoped>

</style>
