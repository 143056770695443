import Button from 'lego-framework/src/components/Button';

export default {
    name: 'approve-btn-success',
    extends: Button,
    props: {
        borderRadius: { type: String, default: '0' },
        text: { type: String, default: '' },
        icon: { type: String, required: false, default: 'fa fa-check' },
        color: { type: String, required: false, default: 'success' }
    }
};
