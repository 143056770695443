import guards from './guards';

const Layout = () => import(/* webpackChunkName: "layout" */'../layouts/MainLayout');

export default {
    path: '/',
    name: 'DashboardLayout',
    redirect: '/app/',
    component: Layout,
    beforeEnter: guards.loggedInGuard,
    children: [
        {
            path: '/drug-index/add-brands/',
            name: 'AddBrands',
            component: () => import(/* webpackChunkName: "home" */'../views/drug-index/add-brands/AddBrandsPage')
        },
        {
            path: '/drug-index/add-category/',
            name: 'AddCategory',
            component: () => import(/* webpackChunkName: "home" */'../views/drug-index/add-category/AddCategoryPage')
        },
        {
            path: '/drug-index/add-drugs/',
            name: 'AddDrugs',
            component: () => import(/* webpackChunkName: "home" */'../views/drug-index/add-drugs/AddDrugsPage')
        },
        {
            path: '/view-drugs/',
            name: 'ViewDrugs',
            component: () => import(/* webpackChunkName: "home" */'../views/drug-index/view-drugs/ViewDrugsPage')
        },
        {
            path: '/issue-reported/',
            name: 'AddDrugs',
            component: () => import(/* webpackChunkName: "home" */'../views/drug-index/issues-reported/IssuesReportedPage')
        },
        {
            path: '/recommended-brands/',
            name: 'RecommendedBrands',
            component: () => import(/* webpackChunkName: "home" */'../views/drug-index/recommended-brands/RecommendedBrandsListPage')
        },
        {
            path: '/view-brands/',
            name: 'ViewBrandsPage',
            component: () => import(/* webpackChunkName: "home" */'../views/drug-index/view-brands/ViewBrandsPage')
        },
        {
            path: '/brand-details/:id',
            name: 'BrandDetails',
            component: () => import(/* webpackChunkName: "home" */'../views/drug-index/view-brands/brand-details-page/BrandDetailsPage')
        },
        {
            path: '/view-category/',
            name: 'ViewCategory',
            component: () => import(/* webpackChunkName: "home" */'../views/drug-index/view-category/ViewCategoryPage')
        },
        {
            path: '/drug-details/:id',
            name: 'DrugDetails',
            component: () => import(/* webpackChunkName: "home" */'../views/drug-index/view-drugs/drug-details-page/DrugDetailsPage')
        }
    ]
};
