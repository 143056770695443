import Button from 'lego-framework/src/components/Button';

export default {
    name: 'save-btn',
    extends: Button,
    props: {
        text: { type: String, default: 'Save' },
        borderRadius: { type: String, default: '0' },
        color: { type: String, default: 'secondary' }
    }
};
