import guards from './guards';

const Layout = () => import(/* webpackChunkName: "layout" */'../layouts/MainLayout');

export default {
    path: '/',
    name: 'DashboardLayout',
    redirect: '/app/',
    component: Layout,
    beforeEnter: guards.loggedInGuard,
    children: [
        {
            path: '/add-article-new/',
            name: 'AddArticle',
            component: () => import(/* webpackChunkName: "home" */'../views/articles/add-article/AddArticlePage')
        },
        {
            path: '/articles-list/',
            name: 'ArticlesList',
            component: () => import(/* webpackChunkName: "home" */'../views/articles/articles-list/ArticlesListPage')
        },
        {
            path: '/articleslist-details-page/:id',
            name: 'ArticleslistDetailsPage',
            component: () => import(/* webpackChunkName: "home" */'../views/articles/articles-list/articleslist-detailspage/ArticleslistDetailsPage')
        },
        {
            path: '/add-article/',
            name: 'ArticlesOldAdd',
            component: () => import(/* webpackChunkName: "home" */'../views/articles/article-old-add/ArticleOldAdd')
        },
        {
            path: '/articles-old-list/',
            name: 'ArticlesOldList',
            component: () => import(/* webpackChunkName: "home" */'../views/articles/articles-old-list/ArticlesOldListPage')
        },
        {
            path: '/articles-oldlist-detailspage/:id',
            name: 'ArticlesOldListDetailsPage',
            component: () => import(/* webpackChunkName: "home" */'../views/articles/articles-old-list/oldlist-detailspage/ArticlesOldListDetailsPage')
        }
    ]
};
