import Modal from 'lego-framework/src/containers/ModalHeaderUpdated';

export default {
    name: 'edit-modal',
    extends: Modal,
    props: {
        alignViewportCenter: { type: Boolean, default: true },
        headerClasses: { type: [String, Array], default: 'bb-1' },
        bodyClasses: { type: [String, Array], default: 'max-h-70vh of-a thin-scrollbar sb-color-fourth' },
        noCloseOnBackdrop: { type: Boolean, default: true },
        noCloseOnEsc: { type: Boolean, default: true }
    }
};
