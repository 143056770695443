import guards from './guards';

const Layout = () => import(/* webpackChunkName: "layout" */'../layouts/MainLayout');

export default {
    path: '/',
    name: 'DashboardLayout',
    redirect: '/app/',
    component: Layout,
    beforeEnter: guards.loggedInGuard,
    children: [
        {
            path: '/add-advertisement/',
            name: 'AddAdvertisement',
            component: () => import(/* webpackChunkName: "home" */'../views/advertisement/add-advertisement/AddAdvertisementPage')
        },
        {
            path: '/advertisement-list/',
            name: 'AdvertisementList',
            component: () => import(/* webpackChunkName: "home" */'../views/advertisement/advertisements-list/AdvertisementsListPage')
        }
    ]
};
