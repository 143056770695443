import guards from './user-guards';

const Layout = () => import(/* webpackChunkName: "login" */'../layouts/UserHomeLayout');
// const Login = () => import('../views/auth/Login');
// const Register = () => import('../views/Registration');
// const Register02 = () => import('../views/RegisterIndividual');
// const Register03 = () => import('../views/RegisterOrganization');

export default {
    path: '/',
    name: 'UserHomeLayout',
    redirect: '/animal-page/',
    beforeEnter: guards.loggedInGuard,
    component: Layout,
    children: [
        {
            path: '/user/home/',
            name: 'UserHome',
            component: () => import('../views/user-home/disease-and-treatments/DiseaseAndTreatments')
        },
        {
            path: '/disease/individual-species/:id',
            name: 'DiseaseIndividualSpecies',
            component: () => import('../views/user-home/disease-and-treatments/DiseaseAndTreatments')
        },
        {
            path: '/diagnosis/list/',
            name: 'UserHomeDiagnoseList',
            component: () => import('../views/user-home/diagnose-list/DiagnoseList')
        },
        {
            path: '/favourite/favourites/',
            name: 'FavouritesList',
            component: () => import('../views/user-home/favourites-list/FavouritesList')
        },
        {
            path: '/individual-diseases/:id/',
            name: 'Fav-Details-List-Page',
            component: () => import('../views/user-home/favourites-list/Fav-Details-List-Page')
        },
        {
            path: '/payment/subscription-plans/',
            name: 'SubscriptionPlan',
            component: () => import('../views/user-home/payment/SubscriptionPlan')
        },
        {
            path: '/payment/payment-modal/',
            name: 'PaymentModal',
            component: () => import('../views/user-home/payment/PaymentModal')
        },
        {
            path: '/dictionary-list/',
            name: 'Dictionary',
            component: () => import('../views/user-home/dictionary/Dictionary')
        },
        {
            path: '/pharmacy/list/',
            name: 'Pharmacy',
            component: () => import('../views/user-home/pharmacy-list/Pharmacy')
        },
        {
            path: '/pharmacy/individual-preperation/:id/',
            name: 'Pharmacy',
            component: () => import('../views/user-home/pharmacy-list/Pharmacy-details-list-page')
        },
        {
            path: '/animal-items/',
            name: 'AnimalItems',
            component: () => import('../components/user-home/AnimalItems')
        },
        // {
        //     path: '/animal-page/',
        //     name: 'AnimalPage',
        //     component: () => import('../views/user-home/diagnose/AnimalPage')
        // },
        {
            path: '/individual-species/:id',
            name: 'IndividualSpecies',
            component: () => import('../views/user-home/diagnose/IndividualSpecies')
        },
        {
            path: '/diagonose-findings/',
            name: 'DiagonoseFindings',
            component: () => import('../views/user-home/diagnose-list/DiagonoseFindings')
        },
        {
            path: '/subscriptionplan/',
            name: 'SubscriptionPlanCard',
            component: () => import('../components/user-home/SubscriptionPlanModal')
        },
        {
            path: '/individual-disease/:id',
            name: 'Individual-disease',
            component: () => import('../views/user-home/diagnose-list/Individual-disease')
        },
        {
            path: '/animal-page/',
            name: 'AnimalPage',
            component: () => import('../views/user-home/disease/AnimalPage')
        },
        {
            path: '/disease-list/:id/',
            name: 'DiseaseList',
            component: () => import('../views/user-home/disease-and-treatments/DiseaseList')
        },
        {
            path: '/disease-modal/',
            name: 'DiseaseModal',
            component: () => import('../views/user-home/disease/DiseaseModal')
        },
        {
            path: '/footer/',
            name: 'Footer',
            component: () => import('../views/user-home/Footer')
        },
        {
            path: '/found-item/',
            name: 'FoundItem',
            component: () => import('../views/user-home/disease/FoundItem')
        },
        {
            path: '/terms-and-conditions/',
            name: 'Termsandconditions',
            component: () => import('../views/user-home/Termsandconditions')
        },
        {
            path: '/privacy-policy/',
            name: 'PrivacyPolicy',
            component: () => import('../views/user-home/PrivacyPolicy')
        },
        {
            path: '/cancellation-policy/',
            name: 'CancellationPolicy',
            component: () => import('../views/user-home/CancellationPolicy')
        },
        {
            path: '/refund-policy/',
            name: 'RefundPolicy',
            component: () => import('../views/user-home/RefundPolicy')
        }
    ]
};
