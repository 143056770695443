import guards from './guards';

const Layout = () => import(/* webpackChunkName: "layout" */'../layouts/MainLayout');

export default {
    path: '/',
    name: 'DashboardLayout',
    redirect: '/app/',
    component: Layout,
    beforeEnter: guards.loggedInGuard,
    children: [
        {
            path: '/flash/',
            name: 'FlashPage',
            component: () => import(/* webpackChunkName: "home" */'../views/flash/FlashPage')
        }
    ]
};
