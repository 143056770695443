import guards from './guards';

const Layout = () => import(/* webpackChunkName: "layout" */'../layouts/MainLayout');

export default {
    path: '/',
    name: 'DashboardLayout',
    redirect: '/app/',
    component: Layout,
    beforeEnter: guards.loggedInGuard,
    children: [
        {
            path: '/add-staff/',
            name: 'AddStaff',
            component: () => import(/* webpackChunkName: "home" */'../views/users/add-staff/AddStaffPage')
        },
        {
            path: '/banned-users/',
            name: 'BannedUsers',
            component: () => import(/* webpackChunkName: "home" */'../views/users/banned-users/BannedusersPage')
        },
        {
            path: '/account/list-pending-approval/',
            name: 'PendingApproval',
            component: () => import(/* webpackChunkName: "home" */'../views/users/pending-approval/PendingApprovalPage')
        },
        {
            path: '/plans-for-users/',
            name: 'PlansForUsers',
            component: () => import(/* webpackChunkName: "home" */'../views/users/plans-for-users/PlansForUsersPage')
        },
        {
            path: '/rejected-users/',
            name: 'RejectedUsers',
            component: () => import(/* webpackChunkName: "home" */'../views/users/rejected-users/RejectedUsersPage')
        },
        {
            path: '/view-staff/',
            name: 'ViewStaff',
            component: () => import(/* webpackChunkName: "home" */'../views/users/view-staff/ViewStaffPage')
        },
        {
            path: '/view-users/',
            name: 'ViewUsers',
            component: () => import(/* webpackChunkName: "home" */'../views/users/view-users/ViewUsersPage')
        }

    ]
};
