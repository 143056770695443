import Button from 'lego-framework/src/components/Button';

export default {
    name: 'change-btn',
    extends: Button,
    props: {
        text: { type: String, default: 'Change' },
        borderRadius: { type: String, default: '0' },
        color: { type: String, required: false, default: 'secondary' }
    }
};
