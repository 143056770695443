import Button from 'lego-framework/src/components/Button';

export default {
    name: 'edit-btn',
    extends: Button,
    props: {
        borderRadius: { type: String, default: '0' },
        icon: { type: String, required: false, default: 'fa fa-trash' },
        color: { type: String, required: false, default: 'danger' },
        size: { type: String, required: false, default: 'sm' }
    }
};
