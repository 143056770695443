import FormCheckbox from 'lego-framework/src/forms/FormCheckbox';

export default {
    name: 'form-checkbox-customized',
    extends: FormCheckbox,
    props: {
        borderRadius: { type: String, default: '0' },
        labelClasses: { type: [String, Array], default: 'text-2 font-weight-700' }
    }
};
