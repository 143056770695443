<template>
    <div class="mb-3 border-r-2 of-h bs-4 pos-r">
        <header-tag :header-colored="headerColored"  class="mb-0 pt-0 bs-5">
            <template #header>
                <div class="pos-r">
                    <div class="fl-y fl-a-c">
                        <div v-if="heading !== ''" class="">
                            <h4 class="font-proxima-bold text-uppercase text-center d-inline text-fs-2"
                                :class="{'text-1': !headerColored,
                                'text-white': headerColored}">
                                {{ heading }}</h4>
                        </div>
                    </div>
                </div>
            </template>
            <template #buttons>
                <slot name="buttons"></slot>
            </template>
        </header-tag>

        <section class="fs--1 font-proxima-regular bg-2 of-a max-h-70vh thin-scrollbar sb-color-fourth bb-1"
                 :class="{'px-lg-4 px-3 pt-3 pb-5': padding, '':minHeight, 'of-a': scrollbar }">
            <slot></slot>
        </section>
    </div>
</template>
<script>

import HeaderTag from '@components/Headertag';

export default {
    name: 'InnerPageCard',
    components: { HeaderTag },
    props: {
        heading: {
            type: String,
            default: ''
        },
        headerColored: {
            type: Boolean,
            default: true
        },
        minHeight: {
            type: Boolean,
            default: true
        },
        padding: {
            type: Boolean,
            default: true
        }
    }
};
</script>
<style scoped lang="scss">

</style>
