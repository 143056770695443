import guards from './guards';

const Layout = () => import(/* webpackChunkName: "layout" */'../layouts/MainLayout');

export default {
    path: '/',
    name: 'DashboardLayout',
    redirect: '/app/',
    component: Layout,
    beforeEnter: guards.loggedInGuard,
    children: [
        {
            path: '/add-batch/',
            name: 'AddBatch',
            component: () => import(/* webpackChunkName: "home" */'../views/directory/add-batch/AddBatchPage')
        },
        {
            path: '/directory/:categId/:categName',
            name: 'AddBatch',
            component: () => import(/* webpackChunkName: "home" */'../views/directory/directory-listing-common/DirectoryListingCommonPage')
        },
        {
            path: '/add-category/',
            name: 'AddCategory',
            component: () => import(/* webpackChunkName: "home" */'../views/directory/add-category/AddCategoryPage')
        },
        {
            path: '/add-contact/',
            name: 'AddContact',
            component: () => import(/* webpackChunkName: "home" */'../views/directory/add-contact/AddContactPage')
        },
        {
            path: '/add-contact-all/',
            name: 'AddContactAll',
            component: () => import(/* webpackChunkName: "home" */'../views/directory/add-contact-all/AddContactsAllPage')
        },
        {
            path: '/add-department-animal-husbandary/',
            name: 'AddDepartmentAnimalHusbandary',
            component: () => import(/* webpackChunkName: "home" */'../views/directory/add-department-animal-husbandary/AddDepartmentAnimalHusbandaryPage')
        },
        {
            path: '/add-farm/',
            name: 'AddFarm',
            component: () => import(/* webpackChunkName: "home" */'../views/directory/add-farm/AddFarmPage')
        },
        {
            path: '/add-university-office/',
            name: 'AddUniversityOffice',
            component: () => import(/* webpackChunkName: "home" */'../views/directory/add-university-office/AddUniversityOfficePage')
        },
        {
            path: '/add-vet-doc-kerala/',
            name: 'AddVetDocKerala',
            component: () => import(/* webpackChunkName: "home" */'../views/directory/add-vet-doc-kerala/AddVetDocKeralaPage')
        }
    ]
};
