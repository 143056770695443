<template>
    <li>
        <div class="fl-x py-1 fl-a-s">
            <custom-size-el class="fl-x  fl-te-c font-weight-600" :class="[{'w-40p': setWidths}]">
                {{label}} <span class="ml-1 mr-2">:</span>
            </custom-size-el>
            <div class="text-2" :class="[{'w-60p': setWidths}]">{{value}}</div>
        </div>
    </li>
</template>

<script>
export default {
    name: 'DetailsListItem',
    props: {
        label: { type: String, default: 'Label' },
        value: { type: String, default: 'Value' },
        labelWidth: { type: String, default: '12r' },
        setWidths: { type: Boolean, default: true }
    }
};
</script>

<style scoped>

</style>
