<template>
    <div class="form-group">
        <div class="form-control-label text-2 font-weight-700" v-html="label"></div>
        <div class="form-control mt-2 round-0 round-0-b bs-1"
             :class="[{'ck-editor-sm' :ckEditorSize ==='sm','ck-editor-md' :ckEditorSize ==='md', 'ck-editor-lg' :ckEditorSize ==='lg'}]">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FormGroup',
    props: {
        label: { type: String, default: '' },
        ckEditorSize: { type: String, default: 'md' }
    }
};
</script>

<style scoped>

</style>
