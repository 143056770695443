<template>
    <div>
        <text-el tag="div" font-weight="600">{{heading}}</text-el>
        <text-el tag="div" class="mt-2" :color="contentColor">
            <slot></slot>
        </text-el>
    </div>
</template>

<script>
export default {
    name: 'HeadingAndContent',
    props: {
        heading: { type: String, default: '' },
        headingColor: { type: String, default: '' },
        contentColor: { type: String, default: '2' }
    }
};
</script>

<style scoped>

</style>
