<template>
    <component :is="tag" class="container-fluid" :class="[marginClasses, paddingClasses, additionalClasses]">
        <slot></slot>
    </component>
</template>
<script>
import marginsAndPaddingsMixin from 'lego-framework/src/mixins/margin-and-padding/marginsAndPaddingsMixin';
export default {
    name: 'container-fluid',
    mixins: [marginsAndPaddingsMixin],
    data () {
        return {};
    },
    props: {
        tag: {
            type: String,
            default: 'div'
        },
        additionalClasses: { type: [String, Array], default: '' }
    }
};
</script>
