const Layout = () => import(/* webpackChunkName: "login" */'../layouts/BlankLayout');
// const Login = () => import('../views/auth/Login');
// const Register = () => import('../views/Registration');
// const Register02 = () => import('../views/RegisterIndividual');
// const Register03 = () => import('../views/RegisterOrganization');

export default {
    path: '/',
    name: 'AuthLayout',
    redirect: '/login/',
    component: Layout,
    children: [
        {
            path: '/login/',
            name: 'LoginPage',
            component: () => import('../views/auth/LoginPage')
        },
        {
            path: '/mobile-login/:id',
            name: 'LoginPage',
            component: () => import('../views/auth/MobileLogin')
        }
    ]
};
