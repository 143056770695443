<template>
    <div
        :class="{'fl-te-c-res': alignEnd, 'fl-x': !alignEnd}"
        class="py-3 px-3 fl-te-c c-border text-white bg-2   bg-primary w-100">
        <div class="">
            <h5 v-if="heading !==''" class="" :class="{'text-black-50': !headerColored}">{{ heading }}</h5>

            <div class="fl-x" v-else>
                <slot name="header"></slot>
            </div>
        </div>
        <div class="btn-group btn-block">
            <slot name="buttons"></slot>
        </div>
    </div>
</template>

<script>

export default {
    name: 'HeaderTag',
    props: {
        heading: {
            type: String,
            default: ''
        },
        headerColored: {
            type: Boolean,
            default: true
        }
    }
};
</script>

<style lang="scss" scoped>
.c-border {
    border-radius: 6px 6px 0px 0px !important;
}
</style>
