import Vue from 'vue';
import VueRouter from 'vue-router';
import appRoutes from './app-layout';
import advertisementRoutes from './advertisement-routes';
import articlesRoutes from './articles-routes';
import diagnosisRoutes from './diagnosis-routes';
import directoryRoutes from './directory-routes';
import diseasesRoutes from './diseases-routes';
import drugIndexRoutes from './drug-index-routes';
import flashRoutes from './flash-routes';
import pharmacyRoutes from './pharmacy-routes';
import plansRoutes from './plans-routes';
import prioratizingRoutes from './prioratizing-routes';
import transactionRoutes from './transaction-routes';
import authRoutes from './auth-layout';
import userHomeRoutes from './user-home-routes';

import usersRoutes from './users-routes';

Vue.use(VueRouter);

const routes = [
    appRoutes,
    advertisementRoutes,
    articlesRoutes,
    diagnosisRoutes,
    directoryRoutes,
    diseasesRoutes,
    drugIndexRoutes,
    flashRoutes,
    pharmacyRoutes,
    plansRoutes,
    prioratizingRoutes,
    transactionRoutes,
    usersRoutes,
    authRoutes,
    userHomeRoutes
];

if (process.env.NODE_ENV !== 'production') {
    const docsRoutes = require('lego-framework/src/router/docs-layout');
    routes.push(docsRoutes.default);
}

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
    // scrollBehavior (to, from, savedPosition) {
    //     if (savedPosition) {
    //         return savedPosition;
    //     } else {
    //         return {
    //             x: 0,
    //             y: 0
    //         };
    //     }
    // }
});

router.beforeResolve((to, from, next) => {
    if (to.name) {
    }
    next();
});

router.afterEach(() => {
});

export default router;
