import guards from './guards';

const Layout = () => import(/* webpackChunkName: "layout" */'../layouts/MainLayout');

export default {
    path: '/',
    name: 'DashboardLayout',
    redirect: '/app/',
    component: Layout,
    beforeEnter: guards.loggedInGuard,
    children: [
        {
            path: '/active-plans/',
            name: 'ActivePlans',
            component: () => import(/* webpackChunkName: "home" */'../views/plans/active-plans/ActivePlansPage')
        },
        {
            path: '/add-plans/',
            name: 'AddPlans',
            component: () => import(/* webpackChunkName: "home" */'../views/plans/add-plans/AddPlansPage')
        },
        {
            path: '/former-plans/',
            name: 'FormerPlans',
            component: () => import(/* webpackChunkName: "home" */'../views/plans/former-plans/FormerPlansPage')
        }
    ]
};
