import Button from 'lego-framework/src/components/DropdownButton';

export default {
    name: 'dropdown-btn-customized',
    extends: Button,
    props: {
        enterAnimation: { type: String, default: 'fade' },
        exitAnimation: { type: String, default: 'fade' }
    }
};
