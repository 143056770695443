import guards from './guards';

const Layout = () => import(/* webpackChunkName: "layout" */'../layouts/MainLayout');

export default {
    path: '/',
    name: 'DashboardLayout',
    redirect: '/app/',
    component: Layout,
    beforeEnter: guards.loggedInGuard,
    children: [
        {
            path: '/add-disease/',
            name: 'AddDisease',
            component: () => import(/* webpackChunkName: "home" */'../views/diseases/add-disease/AddDiseasePage')
        },
        {
            path: '/add-suggestions/',
            name: 'AddSuggestions',
            component: () => import(/* webpackChunkName: "home" */'../views/diseases/add-suggestions/AddSuggestionsPage')
        },
        {
            path: '/dictionary/',
            name: 'DictionaryPage',
            component: () => import(/* webpackChunkName: "home" */'../views/diseases/dictionary/DictionaryPage')
        },
        {
            path: '/Dictionary-Excel-Page/',
            name: 'DictionaryExcelPage',
            component: () => import(/* webpackChunkName: "home" */'../views/diseases/dictionary-excel-exporting/DictionaryExcelPage')
        },
        {
            path: '/disease-issues-reported/',
            name: 'DiseaseIssuesReported',
            component: () => import(/* webpackChunkName: "home" */'../views/diseases/disease-issues-reported/DiseaseIssuesReportedPage')
        },
        {
            path: '/disease-requests/',
            name: 'DiseaseRequests',
            component: () => import(/* webpackChunkName: "home" */'../views/diseases/disease-requests/DiseaseRequestsPage')
        },
        {
            path: '/diseases-list/',
            name: 'DiseaseList',
            component: () => import(/* webpackChunkName: "home" */'../views/diseases/diseases-list/DiseasesListPage')
        },
        {
            path: '/disease-list-details-page/:id/',
            name: 'DiseaseListDetailsPage',
            component: () => import(/* webpackChunkName: "home" */'../views/diseases/diseases-list/disease-list-detailspage/DiseaseListDetailsPage')
        },
        {
            path: '/description-detailspage/:id/',
            name: 'DescriptionDetailsPage',
            component: () => import(/* webpackChunkName: "home" */'../views/diseases/diseases-list/description-details-page/DescriptionDetailsPage')
        },
        {
            path: '/reportsdetailspage/:id/',
            name: 'ReportsDetailsPage',
            component: () => import(/* webpackChunkName: "home" */'../views/diseases/diseases-list/reports-details-page/ReportsDetailsPage')
        },
        {
            path: '/treatment-suggestions/:id/',
            name: 'TreatmentSuggestions',
            component: () => import(/* webpackChunkName: "home" */'../views/diseases/diseases-list/treatmentsuggestion/TreatmentSuggestions')
        },
        {
            path: '/diseases-species/',
            name: 'DiseaseSpecies',
            component: () => import(/* webpackChunkName: "home" */'../views/diseases/diseases-species/DiseasesSpeciesPage')
        },
        {
            path: '/treatments-list/',
            name: 'TreatmentsList',
            component: () => import(/* webpackChunkName: "home" */'../views/diseases/treatments-list/TreatmentsListPage')
        },
        {
            path: '/treatments-suggestions/',
            name: 'TreatmentsSuggestions',
            component: () => import(/* webpackChunkName: "home" */'../views/diseases/treatments-suggestions/TreatmentsSuggestionsPage')
        },
        {
            path: '/treatments-suggestions-approved/',
            name: 'TreatmentsSuggestionsApproved',
            component: () => import(/* webpackChunkName: "home" */'../views/diseases/treatments-suggestions-approved/TreatmentsSuggestionsApprovedPage')
        },
        {
            path: '/user-favourites/',
            name: 'UserFavourites',
            component: () => import(/* webpackChunkName: "home" */'../views/diseases/user-favourites/UserFavouritesPage')
        }
    ]
};
