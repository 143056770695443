import Button from 'lego-framework/src/components/Button';

export default {
    name: 'unban-btn',
    extends: Button,
    props: {
        text: { type: String, default: 'UnBan' },
        borderRadius: { type: String, default: '0' },
        icon: { type: String, required: false, default: 'fa fa-toggle-on' },
        color: { type: String, required: false, default: 'success' },
        size: { type: String, required: false, default: 'sm' }
    }
};
