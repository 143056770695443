<template>
    <ul class="list-unstyled">
        <slot></slot>
    </ul>
</template>

<script>
export default {
    name: 'DetailsList'
};
</script>

<style scoped>

</style>
