// import Button from 'lego-framework/src/components/Button';
import Button from '../components/buttons/btn-customized';
import DropDownButton from '../components/buttons/dropdown-btn-customized';
import DropDownItem from 'lego-framework/src/components/DropDownItem';
import Modal from '../components/modals/base-modal-customized';
import EditModal from '../components/modals/edit-modal';
import CustomHeightElement from 'lego-framework/src/containers/CustomHeightContainer';
import DeleteModal from '../components/modals/DeleteModal';
import ActivateModal from '../components/modals/ActivateModal';
import Tabs from 'lego-framework/src/containers/Tabs';
import Tab from 'lego-framework/src/containers/Tab';
import ClickOutside from 'lego-framework/src/directives/click-outside';
// import FormInput from 'lego-framework/src/forms/FormInput';
import FormInput from '../components/form/form-input-extended';
import FormInputColored from '../components/form/form-input-colored';
import FormRichTextInput from 'lego-framework/src/forms/FormRichTextInput';
import FormMaskInput from 'lego-framework/src/forms/FormMaskInput';
// import FormTextArea from 'lego-framework/src/forms/FormTextArea';
import FormTextArea from '../components/form/form-textarea-extended';
import FormTextAreaLg from '../components/form/form-textarea-lg';
// import FormSelect from 'lego-framework/src/forms/FormSelect';
// import FormSelect from '../components/form/form-select-customized';
import FormSelect from '../components/form/form-select-extended';
import FormCheckbox from '../components/form/form-checkbox-customized';
import FormDatePicker from 'lego-framework/src/forms/FormDatePicker';
import FormGroup from '../components/form/FormGroup';
import FormControl from 'lego-framework/src/forms/BaseFormControl';
// import FormRichTextInput from 'lego-framework/src/forms/FormRichTextInput';

import Form from 'lego-framework/src/forms/PostForm';
import SimpleForm from 'lego-framework/src/forms/SimpleForm';

import FormInfo from '../components/form/FormInfo';

import VueTable from 'lego-framework/src/tables/VueTable';
import VueTableCard from '../components/tables/vue-table-card-customized';

import ErrorIcon from 'lego-framework/src/svg-icons/ErrorIcon';
import SuccessIcon from 'lego-framework/src/svg-icons/SuccessIcon';
import LoadingAnimation from 'lego-framework/src/svg-icons/LoadingAnimation';

import Container from 'lego-framework/src/containers/layout/Container';
import ContainerFluid from '../components/pages/ContainerFluid';
import col12 from 'lego-framework/src/containers/grid/col-12';
import row from 'lego-framework/src/containers/Row';
import card from 'lego-framework/src/containers/layout/Card';
import InnerPageCard from '../components/InnerPageCard';
import TablePage from '../components/pages/TablePage';
import DetailsCard from '../components/cards/DetailsCard';
import DetailsList from '../components/list-items/DetailsList';
import DetailsListItem from '../components/list-items/DetailsListItem';
import HeadingAndContent from '../components/list-items/HeadingAndContent';
import TransitionFade from 'lego-framework/src/components/animate-transition-fade';

import * as validator from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import { messages } from 'vee-validate/dist/locale/en.json';

import ViewButton from '../components/buttons/view-btn';
// import EditButton from 'lego-framework/src/components/EditButton';
import EditButton from '../components/buttons/edit-btn';
import DeleteButton from '../components/buttons/delete-btn';
// import DeleteButton from 'lego-framework/src/components/DeleteButton';
import SaveButton from '../components/buttons/save-btn';
import ButtonPrimary from '../components/buttons/btn-primary';
import AddButton from '../components/buttons/add-btn';
import ChangeButton from '../components/buttons/change-btn';
import SubmitButton from '../components/buttons/submit-btn';
import ApproveButtonSuccess from '../components/buttons/approve-btn-success';
import ApproveButton from '../components/buttons/approve-btn';
import ActivateButton from '../components/buttons/btn-activate';
import RejectButton from '../components/buttons/reject-btn';
import BanButton from '../components/buttons/ban-btn';
import UnbanButton from '../components/buttons/unban-btn';
import ButtonDark from '../components/buttons/btn-dark';
import TransactionsButton from '../components/buttons/transactions-btn';
import ButtonGroup from '../components/buttons/btn-group-customized';
import CodeContainer from '../components/code-container-customized';
import TextElement from 'lego-framework/src/components/typography/TextElement';

import AjaxView from 'lego-framework/src/ajax/AjaxView';
import NotificationsPlugin from 'lego-framework/src/notification';
// Testing...............................
import FormFileInput from 'lego-framework/src/forms/FormFileInput';
import FormAjaxSelect from '../components/form/form-ajax-select-extended';

export default {
    install: function (Vue) {
        Vue.use(ClickOutside);
        Vue.use(NotificationsPlugin);

        Vue.component('btn', Button);
        Vue.component('btn-primary', ButtonPrimary);
        Vue.component('dropdown-btn', DropDownButton);
        Vue.component('dropdown-item', DropDownItem);
        Vue.component('view-btn', ViewButton);
        Vue.component('edit-btn', EditButton);
        Vue.component('add-btn', AddButton);
        Vue.component('delete-btn', DeleteButton);
        Vue.component('save-btn', SaveButton);
        Vue.component('submit-btn', SubmitButton);
        Vue.component('ban-btn', BanButton);
        Vue.component('unban-btn', UnbanButton);
        Vue.component('change-btn', ChangeButton);
        Vue.component('activate-btn', ActivateButton);
        Vue.component('transactions-btn', TransactionsButton);
        Vue.component('approve-btn-success', ApproveButtonSuccess);
        Vue.component('approve-btn', ApproveButton);
        Vue.component('reject-btn', RejectButton);
        Vue.component('btn-dark', ButtonDark);
        Vue.component('btn-group', ButtonGroup);
        Vue.component('tabs', Tabs);
        Vue.component('tab', Tab);
        Vue.component('modal', Modal);
        Vue.component('edit-modal', EditModal);
        Vue.component('delete-modal', DeleteModal);
        Vue.component('activate-modal', ActivateModal);
        Vue.component('code-container', CodeContainer);
        Vue.component('custom-size-el', CustomHeightElement);
        Vue.component('transition-fade', TransitionFade);

        Vue.component('b-form', Form);
        Vue.component('s-form', SimpleForm);
        Vue.component('form-info', FormInfo);
        Vue.component('form-group', FormGroup);

        Vue.component('validated-input', FormInput);
        Vue.component('validated-input-colored', FormInputColored);
        Vue.component('validated-text-area', FormTextArea);
        Vue.component('validated-text-area-lg', FormTextAreaLg);
        Vue.component('validated-mask-input', FormMaskInput);
        Vue.component('validated-select', FormSelect);
        Vue.component('validated-vue-select', FormSelect);
        Vue.component('validated-date-picker', FormDatePicker);
        Vue.component('validated-checkbox', FormCheckbox);
        Vue.component('validated-rich-text-input', FormRichTextInput);
        Vue.component('validated-ajax-select', FormAjaxSelect);
        Vue.component('validated-form-control', FormControl);
        // Vue.component('validated-input-ckeditor', FormRichTextInput);

        Vue.component('vue-table', VueTable);
        Vue.component('vue-table-card', VueTableCard);

        Vue.component('error-icon', ErrorIcon);
        Vue.component('success-icon', SuccessIcon);
        Vue.component('loading-animation', LoadingAnimation);

        Vue.component('text-el', TextElement);
        Vue.component('container', Container);
        Vue.component('container-fluid', ContainerFluid);
        Vue.component('col-12', col12);
        Vue.component('row', row);
        Vue.component('card', card);
        Vue.component('inner-page-card', InnerPageCard);
        Vue.component('table-page', TablePage);
        Vue.component('table-page', TablePage);
        Vue.component('details-card', DetailsCard);
        Vue.component('details-list', DetailsList);
        Vue.component('details-list-item', DetailsListItem);
        Vue.component('heading-and-content', HeadingAndContent);

        Vue.component('ajax-view', AjaxView);

        Vue.component('validation-provider', validator.ValidationProvider);
        Vue.component('validation-observer', validator.ValidationObserver);
        // testing.............
        Vue.component('validated-file-input', FormFileInput);

        Object.keys(rules).forEach(rule => {
            validator.extend(rule, {
                ...rules[rule], // copies rule configuration
                message: messages[rule] // assign message
            });
        });
    }
};
