import FormInput from 'lego-framework/src/forms/FormInput';
// const requiredStr = '&#60;sup class="text-danger"&#62;*&#60;/sup&#62;';
export default {
    name: 'form-input-extended',
    extends: FormInput,
    props: {
        borderRadius: { type: String, default: '0' },
        labelClasses: { type: [String, Array], default: 'text-third font-weight-700' },
        inputClasses: { type: [String, Array], default: 'mt-2' }
    }
};
