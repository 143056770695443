import Modal from 'lego-framework/src/ajax/ConfirmPostModal';

export default {
    name: 'activate-modal',
    extends: Modal,
    props: {
        width: { type: String, default: '30r' },
        title: { type: String, default: 'Activate' },
        headerColor: { type: String, default: 'info' },
        bodyColor: { type: String, default: 'info' },
        dialog: { type: Boolean, default: true },
        okButton: { type: String, default: 'Activate' },
        okButtonColor: { type: String, default: 'primary' },
        cancelButton: { type: String, default: 'No' },
        cancelButtonColor: { type: String, default: 'primary' }
    }
};
